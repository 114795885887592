import React from "react";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./scss/style.scss";
import { connect } from "react-redux";
import authService from "./services/authService";
import "./i18n";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Layout = React.lazy(() => import("./containers/Layout"));
const Login = React.lazy(() => import("./views/login/Login"));
const ForgotPassword = React.lazy(() => import("./views/login/ForgotPassword"));
const ForgotPasswordNoted = React.lazy(() => import("./views/login/ForgotPasswordNoted"));
const ResetPassword = React.lazy(() => import("./views/login/ResetPassword"));
const ResetPasswordConfirmed = React.lazy(() => import("./views/login/ResetPasswordConfirmed"));
const Register = React.lazy(() => import("./views/register/Register"));
const RegisterNoted = React.lazy(() => import("./views/register/RegisterNoted"));
const RegisterConfirmed = React.lazy(() =>  import("./views/register/RegisterConfirmed"));
const Error404 = React.lazy(() => import("./views/errors/Error404"));
const Error500 = React.lazy(() => import("./views/errors/Error404"));
const Logout = React.lazy(() => import("./views/logout/Logout"));

const AuthenticatedRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const UnauthenticatedRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoggedIn: false,
    };
  }
  componentDidMount() {
    const { isLoggedIn } = this.props;

    if (isLoggedIn) {
      authService
        .info()
        .then((res) => {
          this.setState({
            isLoggedIn: isLoggedIn,
          });
        })
        .catch((error) => {
          authService.logout();
          window.location.reload();
        });
    }
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            {/*<Route path="/" render={(props) => <Layout {...props} />} />*/}
            <UnauthenticatedRoute
              path="/login/:method?/:param1?"
              component={Login}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/ForgotPassword"
              component={ForgotPassword}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/ForgotPasswordNoted"
              component={ForgotPasswordNoted}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/resetPassword/:resetpasswordToken"
              component={ResetPassword}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/ResetPasswordConfirmed"
              component={ResetPasswordConfirmed}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/register"
              component={Register}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/registrationnoted"
              component={RegisterNoted}
              isAuthenticated={this.state.isLoggedIn}
            />
            <UnauthenticatedRoute
              path="/registrationconfirmed/:registrationtoken"
              component={RegisterConfirmed}
              isAuthenticated={this.state.isLoggedIn}
            />
            <Route path='/privacy-policy' component={() => { 
              if (window.process.env.BATOAIR_APP_RESPECT_ENVIRONMENT_VARIABLES === "true")
              {
                window.location.href = window.process.env.BATOAIR_APP_PRIVACY_POLICY_URL; 
              }
              else
              {
                window.location.href = 'https://www.inspektion-kostenlos.de/s/datenschutz'; 
              }
                return null;
            }}/>
            <Route
              path="/404"
              render={(props) => <Error404 {...props} />}
            />
            <Route
              path="/500"
              render={(props) => <Error500 {...props} />}
            />
            <Route
              path="/logout"
              render={(props) => <Logout {...props} />}
            />
            <AuthenticatedRoute
              path="/"
              component={Layout}
              isAuthenticated={this.state.isLoggedIn}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.userReducer;
  return {
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(App);
